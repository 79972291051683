import { Text, Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { type Shift, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { generatePath } from "react-router-dom";

import { getFormattedShiftPayBreakdown } from "../../Shift/getFormattedShiftPayBreakdown";
import { WorkerShiftCard } from "../../Shift/WorkerShift/Card";

interface ScheduledShiftListProps {
  shifts: Shift[];
}

export function ScheduledShiftList(props: ScheduledShiftListProps) {
  const { shifts } = props;

  return (
    <Stack spacing={4}>
      {shifts.length > 0 && (
        <Stack direction="row" alignItems="center" spacing={4}>
          <Title component="h2" variant="h5" paddingY={3}>
            Scheduled{" "}
          </Title>
          <Text semibold variant="body2" color={(theme) => theme.palette.text.tertiary}>
            {shifts.length}
          </Text>
        </Stack>
      )}

      {shifts.map((shift) => {
        const payBreakdown = getFormattedShiftPayBreakdown(
          shift.offer?.pay.value,
          shift.finalPay,
          shift.originalAmount
        );

        return (
          <WorkerShiftCard
            key={shift._id}
            href={generatePath(DeprecatedGlobalAppV1Paths.MY_SHIFT_DETAIL, {
              shiftId: shift._id,
            })}
            workplaceName={shift.facility.name}
            workplaceTimezone={shift.facility.tmz ?? ""}
            shiftTimeSlot={shiftPeriodToTimeSlot[shift.name]}
            shiftStart={shift.start}
            shiftEnd={shift.end}
            shiftAgentReq={shift.agentReq}
            shiftHourlyPay={payBreakdown?.hourlyPay}
            shiftTotalPay={payBreakdown?.totalPay}
          />
        );
      })}
    </Stack>
  );
}
