import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { Illustration } from "@src/appV2/redesign/components/Illustration";

export function EmptyPageState() {
  return (
    <Stack alignItems="center" justifyContent="center" height="100%" spacing={8}>
      <Illustration type="browse-shifts" />
      <Text variant="body1">You don’t have any upcoming shifts!</Text>
      <Button variant="contained" size="medium" href="/home-v2/shift-discovery">
        Browse Shifts
      </Button>
    </Stack>
  );
}
