import { Title } from "@clipboard-health/ui-react";

import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";

interface PageTitleProps {
  children: React.ReactNode;
}

export function PageTitle(props: PageTitleProps) {
  const { children } = props;

  return (
    <PageHeaderWrapper sx={{ paddingBottom: 6, paddingX: 5 }}>
      <Title component="h1" variant="h4">
        {children}
      </Title>
    </PageHeaderWrapper>
  );
}
