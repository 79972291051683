import { Box, Skeleton } from "@mui/material";

import { CalloutsWrapper } from "../../components/CalloutsWrapper";
import { ActiveNegotiationsCallout } from "./ActiveNegotiationsCallout";
import { PendingBreakPaymentRequestsCallout } from "./PendingBreakPaymentRequestsCallout";
import { SentHomeRequestsCallout } from "./SentHomeRequestsCallout";
import { ShiftInviteCallout } from "./ShiftInviteCallout";
import { UnverifiedShiftsCallout } from "./UnverifiedShiftsCallout";
import { WorkplaceProposalCallout } from "./WorkplaceProposalCallout";

interface Props {
  shiftInviteCount: number;
  unverifiedShiftsCount: number;
  activeNegotiationsCount: number;
  facilityProposalsCount: number;
  extraWorkedRequestsCount: number;
  sentHomeRequestsCount: number;
  isLoadingShiftInvites: boolean;
  isLoadingUnverifiedShifts: boolean;
  isLoadingNegotiations: boolean;
  isLoadingSentHome: boolean;
  isLoadingExtraWorked: boolean;
}

export function WorkerShiftsCalloutsContainer(props: Props) {
  const {
    shiftInviteCount,
    unverifiedShiftsCount,
    activeNegotiationsCount,
    facilityProposalsCount,
    extraWorkedRequestsCount,
    sentHomeRequestsCount,
    isLoadingShiftInvites,
    isLoadingUnverifiedShifts,
    isLoadingNegotiations,
    isLoadingSentHome,
    isLoadingExtraWorked,
  } = props;

  const isLoading =
    isLoadingShiftInvites ||
    isLoadingUnverifiedShifts ||
    isLoadingNegotiations ||
    isLoadingSentHome ||
    isLoadingExtraWorked;

  const hasCallouts =
    shiftInviteCount > 0 ||
    unverifiedShiftsCount > 0 ||
    activeNegotiationsCount > 0 ||
    facilityProposalsCount > 0 ||
    extraWorkedRequestsCount > 0 ||
    sentHomeRequestsCount > 0;

  return (
    <Box sx={{ marginX: -4 }}>
      {isLoading && (
        <CalloutsWrapper sx={{ paddingX: 4 }}>
          {Array.from({ length: 2 }).map((_, index) => (
            <Skeleton
              // eslint-disable-next-line react/no-array-index-key
              key={`skeleton-${index}`}
              variant="rounded"
              sx={{
                height: 36,
                width: "60%",
              }}
              aria-label="Loading shift notifications"
            />
          ))}
        </CalloutsWrapper>
      )}
      {hasCallouts && (
        <CalloutsWrapper sx={{ paddingX: 4 }}>
          {shiftInviteCount > 0 && <ShiftInviteCallout shiftInviteCount={shiftInviteCount} />}
          {unverifiedShiftsCount > 0 && (
            <UnverifiedShiftsCallout unverifiedShiftsCount={unverifiedShiftsCount} />
          )}
          {activeNegotiationsCount > 0 && (
            <ActiveNegotiationsCallout activeNegotiationsCount={activeNegotiationsCount} />
          )}
          {facilityProposalsCount > 0 && (
            <WorkplaceProposalCallout facilityProposalsCount={facilityProposalsCount} />
          )}
          {extraWorkedRequestsCount > 0 && (
            <PendingBreakPaymentRequestsCallout
              extraWorkedRequestsCount={extraWorkedRequestsCount}
            />
          )}
          {sentHomeRequestsCount > 0 && (
            <SentHomeRequestsCallout sentHomeRequestsCount={sentHomeRequestsCount} />
          )}
        </CalloutsWrapper>
      )}
    </Box>
  );
}
