import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import pluralize from "pluralize";
import { useHistory, useLocation } from "react-router-dom";

import { Callout } from "../../components/Callout";

interface ActiveNegotiationsCalloutProps {
  activeNegotiationsCount: number;
}

export function ActiveNegotiationsCallout({
  activeNegotiationsCount,
}: ActiveNegotiationsCalloutProps) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return (
    <Callout
      title={`${activeNegotiationsCount} active rate ${pluralize(
        "negotiation",
        activeNegotiationsCount
      )}`}
      iconType="coin"
      sx={(theme) => ({
        backgroundColor: theme.palette.intent?.success.background,
        color: theme.palette.intent?.success.text,
      })}
      onClick={() => {
        const params = new URLSearchParams();
        if (searchParams.get("negotiationId")) {
          params.set("negotiationId", searchParams.get("negotiationId")!);
        }

        history.push({
          pathname: DeprecatedGlobalAppV1Paths.RATE_NEGOTIATIONS,
          search: params.toString(),
        });
      }}
    />
  );
}
