import { CameraPermissionDeniedError } from "@src/appV2/Accounts/DocumentDetails/errors";
import { BottomSheetIllustrationContent } from "@src/appV2/redesign/components/BottomSheetIllustrationContent";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

export interface ErrorUploadingTimesheetContentProps {
  uploadError?: Error | CameraPermissionDeniedError;
  onTryAgainClick: () => void;
  onCancelClick: () => void;
}

export function ErrorUploadingTimesheetContent(props: ErrorUploadingTimesheetContentProps) {
  const { onTryAgainClick, onCancelClick, uploadError } = props;

  const title =
    uploadError instanceof CameraPermissionDeniedError
      ? "Camera permission denied"
      : "Please try uploading again";
  const description =
    uploadError instanceof CameraPermissionDeniedError
      ? "Please grant camera permission to upload timesheets in your device settings for Clipboard Health application."
      : "There was an issue with the upload of your document";

  return (
    <>
      <BottomSheetIllustrationContent
        illustrationType="document-rejected"
        title={title}
        description={description}
      />
      <DialogFooter
        orientation="vertical"
        closeButtonAriaLabel="Cancel timesheet upload"
        sx={{
          paddingTop: 11,
        }}
        onClose={onCancelClick}
      >
        <Button
          fullWidth
          size="large"
          onClick={() => {
            onTryAgainClick();
          }}
        >
          Upload timesheet
        </Button>
      </DialogFooter>
    </>
  );
}
