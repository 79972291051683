import { isDefined } from "@clipboard-health/util-ts";
import { useFutureShifts } from "@src/appV2/Shifts/MyShifts/api/useFutureShifts";
import { useNextTwoDaysShifts } from "@src/appV2/Shifts/MyShifts/api/useNextTwoDaysShifts";
import { type Worker } from "@src/appV2/Worker/api/types";
import { compareAsc, parseISO } from "date-fns";
import { useCallback, useMemo } from "react";

export interface UseGetWorkerShiftsOptions {
  worker: Pick<Worker, "tmz">;
}

export function useGetWorkerShifts(options: UseGetWorkerShiftsOptions) {
  const { worker } = options;

  const {
    isLoading: isLoadingNextTwoDaysShifts,
    data: nextTwoDaysShiftsData,
    refetch: refetchNextTwoDaysShifts,
  } = useNextTwoDaysShifts(worker.tmz ?? "", { enabled: isDefined(worker.tmz) });

  const {
    isLoading: isLoadingFutureShifts,
    data: futureShiftsData,
    refetch: refetchFutureShifts,
  } = useFutureShifts(worker.tmz ?? "", { enabled: isDefined(worker.tmz) });

  const refetch = useCallback(async () => {
    void refetchNextTwoDaysShifts();
    void refetchFutureShifts();
  }, [refetchNextTwoDaysShifts, refetchFutureShifts]);

  const shifts = useMemo(() => {
    const nextTwoDaysShifts = nextTwoDaysShiftsData?.response ?? [];
    const futureShifts = futureShiftsData?.response ?? [];

    return [...nextTwoDaysShifts, ...futureShifts].sort((a, b) => {
      return compareAsc(parseISO(a.start), parseISO(b.start));
    });
  }, [nextTwoDaysShiftsData, futureShiftsData]);

  const areShiftsLoading = isLoadingNextTwoDaysShifts || isLoadingFutureShifts;

  return {
    shifts,
    isLoading: areShiftsLoading,
    refetch,
  };
}
