import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, ThemeProvider } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { type Shift, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { PageHeaderTopRow } from "../../components/PageHeaderTopRow";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { ShiftWithContentCard } from "../../Shift/Card/CardWithContent";
import { getFormattedShiftPayBreakdown } from "../../Shift/getFormattedShiftPayBreakdown";
import { getRedesignTheme } from "../../theming";
import { PageContent } from "../components/PageContent";
import { UnverifiedShiftCardContent } from "./CardContent";
import { UnverifiedShiftsLoadingPageState } from "./LoadingPageState";
import { UnverifiedShiftsEvents } from "./UnverifiedShiftsEvents";
import { UploadTimesheetBottomSheet } from "./UploadTimesheet/UploadTimesheetBottomSheet";
import {
  useWorkerUnverifiedShifts,
  WORKER_UNVERIFIED_SHIFTS_URL,
} from "./useWorkerUnverifiedShifts";

export function UnverifiedShiftsPage() {
  const uploadTimesheetBottomSheetModalState = useModalState();
  const [uploadTimesheetShift, setUploadTimesheetShift] = useState<Shift>();

  const {
    data: unverifiedShiftsData,
    isLoading,
    refetch: refetchUnverifiedShifts,
  } = useWorkerUnverifiedShifts();
  const queryClient = useQueryClient();
  const unverifiedShifts = unverifiedShiftsData?.response ?? [];

  return (
    <ThemeProvider theme={getRedesignTheme()}>
      <PageHeaderWrapper>
        <PageHeaderTopRow title="Unverified Shifts" />
      </PageHeaderWrapper>
      <PageContent>
        <PullToRefresh
          onRefresh={async () => {
            await refetchUnverifiedShifts();
          }}
        />
        {isLoading && <UnverifiedShiftsLoadingPageState />}
        {!isLoading && (
          <Stack spacing={4}>
            {unverifiedShifts.map((shift) => {
              const shiftPayBreakdown = getFormattedShiftPayBreakdown(
                shift.offer?.pay.value,
                shift.finalPay,
                shift.originalAmount
              );

              const isTimesheetUploaded =
                isDefined(shift.timecard?.files) && shift.timecard?.files.length > 0;

              return (
                <ShiftWithContentCard
                  key={shift._id}
                  shiftTimeSlot={shiftPeriodToTimeSlot[shift.name]}
                  shiftStart={shift.start}
                  shiftEnd={shift.end}
                  shiftAgentReq={shift.agentReq}
                  shiftHourlyPay={shiftPayBreakdown?.hourlyPay}
                  shiftTotalPay={shiftPayBreakdown?.totalPay}
                  workplaceName={shift.facility.name}
                  workplaceTimezone={shift.facility.tmz ?? ""}
                >
                  <UnverifiedShiftCardContent
                    isTimesheetUploaded={isTimesheetUploaded}
                    onUploadTimesheetClick={() => {
                      setUploadTimesheetShift(shift);
                      uploadTimesheetBottomSheetModalState.openModal();
                    }}
                  />
                </ShiftWithContentCard>
              );
            })}
          </Stack>
        )}
        <UploadTimesheetBottomSheet
          modalState={uploadTimesheetBottomSheetModalState}
          shiftId={uploadTimesheetShift?._id}
          isTimesheetUploaded={(uploadTimesheetShift?.timecard?.files?.length ?? 0) > 0}
          onUploadStarted={() => {
            logEvent(APP_V2_APP_EVENTS.UNVERIFIED_SHIFT_ACTION, {
              type: UnverifiedShiftsEvents.TIMESHEET_UPLOAD_STARTED,
              shiftId: uploadTimesheetShift?._id,
              workerId: uploadTimesheetShift?.agentId,
              workplaceId: uploadTimesheetShift?.facilityId,
            });
          }}
          onSuccess={async ({ uploadDurationInMs, updateTimecardDurationInMs }) => {
            const shift = uploadTimesheetShift;
            const isTimesheetUploaded = (shift?.timecard?.files?.length ?? 0) > 0;
            setUploadTimesheetShift(undefined);
            await queryClient.invalidateQueries({ queryKey: [WORKER_UNVERIFIED_SHIFTS_URL] });

            if (isDefined(shift)) {
              logEvent(APP_V2_APP_EVENTS.UNVERIFIED_SHIFT_ACTION, {
                type: UnverifiedShiftsEvents.TIMESHEET_UPLOADED,
                shiftId: shift._id,
                workerId: shift.agentId,
                workplaceId: shift.facilityId,
                isReupload: isTimesheetUploaded,
                uploadDurationInMs,
                updateTimecardDurationInMs,
              });
            }
          }}
          onError={(error) => {
            if (isDefined(uploadTimesheetShift)) {
              logEvent(APP_V2_APP_EVENTS.UNVERIFIED_SHIFT_ACTION, {
                type: UnverifiedShiftsEvents.TIMESHEET_UPLOAD_ERROR,
                shiftId: uploadTimesheetShift._id,
                workerId: uploadTimesheetShift.agentId,
                workplaceId: uploadTimesheetShift.facilityId,
                error: error?.message ?? "Unknown error",
              });
            }

            setUploadTimesheetShift(undefined);
          }}
        />
      </PageContent>
    </ThemeProvider>
  );
}
