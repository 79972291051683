import { Paper } from "@mui/material";

interface PageContentProps {
  children: React.ReactNode;
}

export function PageContent(props: PageContentProps) {
  const { children } = props;
  return (
    <Paper variant="primary" sx={{ paddingX: 5, paddingBottom: 4, overflow: "auto", flexGrow: 1 }}>
      {children}
    </Paper>
  );
}
