import { RootPaths } from "@src/appV2/App/paths";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { Callout } from "../../components/Callout";

interface ShiftInviteCalloutProps {
  shiftInviteCount: number;
}

export function ShiftInviteCallout({ shiftInviteCount }: ShiftInviteCalloutProps) {
  const history = useHistory();

  return (
    <Callout
      title={`${shiftInviteCount} pending ${pluralize("shift invite", shiftInviteCount)} to view`}
      iconType="calendar_with_cells"
      sx={(theme) => ({
        color: theme.palette.shift?.invite.text,
        backgroundColor: theme.palette.shift?.invite.background,
      })}
      onClick={() => {
        history.push(`${RootPaths.APP_V2_HOME}/myShifts/shiftInvites`);
      }}
    />
  );
}
