import { UNVERIFIED_SHIFTS_FULL_PATH } from "@src/appV2/Shifts/UnverifiedShifts/constants";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { Callout } from "../../components/Callout";

interface UnverifiedShiftsCalloutProps {
  unverifiedShiftsCount: number;
}

export function UnverifiedShiftsCallout({ unverifiedShiftsCount }: UnverifiedShiftsCalloutProps) {
  const history = useHistory();

  return (
    <Callout
      title={`${unverifiedShiftsCount} unverified ${pluralize("shift", unverifiedShiftsCount)}`}
      iconType="triangle-exclamation"
      sx={(theme) => ({
        backgroundColor: theme.palette.intent?.error.background,
        color: theme.palette.intent?.error.text,
      })}
      onClick={() => {
        history.push(UNVERIFIED_SHIFTS_FULL_PATH);
      }}
    />
  );
}
