import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import pluralize from "pluralize";
import { useHistory } from "react-router-dom";

import { Callout } from "../../components/Callout";

interface SentHomeRequestsCalloutProps {
  sentHomeRequestsCount: number;
}

export function SentHomeRequestsCallout({ sentHomeRequestsCount }: SentHomeRequestsCalloutProps) {
  const history = useHistory();

  return (
    <Callout
      title={`${sentHomeRequestsCount} pending sent home ${pluralize(
        "request",
        sentHomeRequestsCount
      )}`}
      iconType="document"
      sx={(theme) => ({
        backgroundColor: theme.palette.background.secondary,
        color: theme.palette.text.secondary,
      })}
      onClick={() => {
        history.push(DeprecatedGlobalAppV1Paths.PENDING_SENT_HOME_REQUESTS);
      }}
    />
  );
}
